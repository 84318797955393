@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 8px solid #b5b5be;
    border-top: 8px solid #FF4F10; 
    border-right: 8px solid #FF4F10;
    border-bottom: 8px solid #FF4F10;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    align-self: center;
  }
